import Head from "next/head";

const PageHead = ({ title, description, keywords }) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description || 'Bestpath为国际学生提供AI选课、一对一导师授课、论文创作指导等服务。利用先进AI技术和专业导师资源,打造个性化学习体验,提升学习效率与学术成果。'} />
        <meta name="keywords" content={keywords || 'AI教育, 个性化学习, 国际学生辅导, 一对一导师, 论文指导, 智能选课, 在线教育.'} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
    </>
  );
};

export default PageHead;
